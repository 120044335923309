import React from "react";
import { Container, Col, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAddressCard, faUsers, faLocationArrow, faComments } from "@fortawesome/free-solid-svg-icons"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"

// styles
const Footerbgcontainer = styled(Container)`
  padding: 0;
  width: 100%;
  position: relative;

  & .row:first-of-type {
    min-height: 650px;
    background: ${({ theme }) => theme.colours.primary.dark2};
    background: ${({ theme }) => theme.colours.primary.black};
    padding: 0;
    margin: 0;
    z-index: -1;
 }

 & .row:first-of-type div:first-of-type {
   opacity: 0.6;
  }

  & .panel {
    max-width: 425px;
    margin: 0 auto;
  }

  & .gatsby-image-wrapper {
    height: 100%;
  }
 }

 @media (min-width: 768px) { 

  max-height: 600px;
  overflow: hidden;


  & .row:first-of-type {
    min-height: 400px;
    background: ${({ theme }) => theme.colours.primary.black};
  }
}
  `

const Footerrow = styled(Row)`
  // color: ${({ theme }) => theme.colours.common.white};
  padding: 0;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);

  @media (min-width: 768px) { 
    top: 0;
    width: 100%;
    font-size: 0.7em;
  }

  @media (min-width: 992px) { 
    top: 0;
    width: 100%;
    font-size: 0.8em;
  }

  @media (min-width: 1200px) { 
    left: 50%;
    font-size: 1em;
  }
`

const Footerheader = styled.h2`
  color: ${({ theme }) => theme.colours.common.white};
  font-size: 22px;
  text-transform: uppercase;
  display: block;
  padding-bottom: 15px;
  border-bottom: solid 1px ${({ theme }) => theme.colours.primary.main};
  margin: 20px 0;
  line-height: 32px;

  & svg:not(:root).svg-inline--fa {
    width: 32px;
    height: 32px;
    color: ${({ theme }) => theme.colours.primary.main};
    margin-right: 10px;
    display: block;
    float: left;
  }

  @media (min-width: 768px) { 
    margin: 20px 25px;
  }

  @media (min-width: 992px) { 
    margin: 20px 50px;
  }
`

const Footercontent = styled.div`
    margin-left: 0px;
    color: ${({ theme }) => theme.colours.common.white};

    & ul {
      list-style: none;
      padding-left: 0px;
    }

    & a {
      color: ${({ theme }) => theme.colours.common.white};
      text-decoration: none;
    }

    & a:hover {
      color: ${({ theme }) => theme.colours.primary.main};
      
    }



    @media (min-width: 768px) { 
      margin-left: 35px;
      font-size: 18px;


      & ul {
        padding-left: 20px;
      }
    }


`


function Sitefooter({ footerimage }) {
  const image = getImage(footerimage.imageFile);

  const data = useStaticQuery(graphql`
    query {
      directus {
        settings {
          contact_details
        }
        services(filter: {status: {_eq: "Published"}}) {
          id
          slug
          title
        }
        pages(filter: {status: {_eq: "Published"}}) {
          id
          title
          slug
        }
      }
      
  }
  `)

  const servicesNode = data.directus.services;
  const pagesNode = data.directus.pages;

  return (
    <>
  <Footerbgcontainer fluid>
    <Row>
      <Col xs={12} md={12} className="px-0 d-md-block">
        <GatsbyImage image={image} alt="" objectPosition="top" />
      </Col>
    </Row>
    <Footerrow className="footerPanel">
    <Col xs={12} md={4}>
      <Footerheader><FontAwesomeIcon icon={faAddressCard} /> Contact Details</Footerheader>
        <Footercontent
          dangerouslySetInnerHTML={{
            __html: data.directus.settings.contact_details,
        }} />
    </Col>
    <Col xs={12} md={4} className="px-0 d-none d-md-block">
      <Footerheader><FontAwesomeIcon icon={faUsers} /> Services</Footerheader>
      <Footercontent>
        <ul>
      {servicesNode.map((servicesNode, i) => (
          <li> <Link to={`/services/${servicesNode.slug}`} key={i}>{servicesNode.title}</Link></li> ))}
        </ul>   
        </Footercontent> 
        
    </Col>
    <Col xs={12} md={4}>
      <Footerheader><FontAwesomeIcon icon={faLocationArrow} /> Quick Links</Footerheader>
      <Footercontent>
        <ul>
      {pagesNode.map((pagesNode, i) => (
          <li> <Link to={`/${pagesNode.slug}`} key={i}>{pagesNode.title}</Link></li> ))}
        </ul>   
        </Footercontent> 
    </Col>
    {/* <Col xs={12} md={3}>
      <Footerheader><FontAwesomeIcon icon={faComments} /> Connect With Us</Footerheader>
    </Col> */}
    </Footerrow>
  </Footerbgcontainer>
  </>
  );
}

export default Sitefooter;