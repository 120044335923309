import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

var Scroll = require('react-scroll');

function Sitenav({ logo }) {
  const image = getImage(logo.imageFile);
  const [header, setHeader] = useState("header");

  const listenScrollEvent = event => {
    if (window.scrollY < 10) {
      return setHeader("navTop");
    } else if (window.scrollY > 10) {
      return setHeader("navScroll");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <>
  <Navbar sticky="top" collapseOnSelect expand="lg" className={header}>
    <Container fluid className="navContainer">
    <Navbar.Brand href="/#" className="mr-auto" onClick={Scroll.scrollToTop}><GatsbyImage image={image} alt={logo.title} /></Navbar.Brand>
    <a href="tel:+61296205555" className="navPhone d-none d-md-block d-lg-none"><FontAwesomeIcon icon={faPhone} /> (02) 9620 5555</a>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" className="navbar-dark" />
    <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="ml-auto">
      <div className="item"><Nav.Link href="/#" onClick={Scroll.scrollToTop}>Home</Nav.Link></div>
      <div className="item"><Nav.Link href="/about" onClick={Scroll.scrollToTop}>Who We Are</Nav.Link></div>
      <div className="item"><Nav.Link href="/services" onClick={Scroll.scrollToTop}>Services</Nav.Link></div>
      <div className="item"><Nav.Link href="/projects" onClick={Scroll.scrollToTop}>Projects</Nav.Link></div>
      <div className="item"><Nav.Link href="/gallery" onClick={Scroll.scrollToTop}>Gallery</Nav.Link></div>
      <div className="item"><Nav.Link href="/contact" onClick={Scroll.scrollToTop}>Contact</Nav.Link></div>
      <div className="callus item d-none d-xl-block"><Nav.Link href="tel:+61296205555">
      <FontAwesomeIcon icon={faPhone} /> (02) 9620 5555</Nav.Link></div>
    </Nav>
    </Navbar.Collapse>
    </Container>
  </Navbar>
  </>
  );
}

export default Sitenav;